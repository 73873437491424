const getReadUrls = (): string[] => {
  const json = localStorage.getItem("readUrls");
  if (json) {
    return JSON.parse(json);
  } else {
    return [];
  }
};

let readUrls: string[] = getReadUrls();
console.log(readUrls);

const setReadUrl = (url: string) => {
  console.log(readUrls);
  readUrls.push(url);
  localStorage.setItem("readUrls", JSON.stringify(readUrls));
}

const gcReadUrls = (urls: string[]) => {
  readUrls = readUrls.filter((x) => urls.includes(x));
  localStorage.setItem("readUrls", JSON.stringify(readUrls));
}

export { readUrls, setReadUrl, gcReadUrls };
