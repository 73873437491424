import React, { FunctionComponent, useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from "@material-ui/lab/Pagination";
import Link from "@material-ui/core/Link";

import { WxcHomepageArticle, fetchHomepage } from "./WxcHomepageParser";
import Article from "../Article";
import { readUrls, setReadUrl, gcReadUrls } from "../ArticleReadCache";
import { play, pause } from "../AudioPlayer";

import styles from "./Home.module.css";

const paginationLimit = 20;

const Home: FunctionComponent = () => {
  const [fetchState, setFetchState] = useState<"none" | "fetching" | "error" | "done">("none");
  const [articles, setArticles] = useState<WxcHomepageArticle[]>([]);
  const [page, setPage] = useState<number>(1);

  const [openArticle, setOpenArticle] = useState<WxcHomepageArticle | undefined>();

  useEffect(() => {
    setFetchState("fetching");

    fetchHomepage()
      .then((a) => {
        setArticles(a);
        gcReadUrls(a.map((art) => art.url));
        setFetchState("done");
      })
      .catch((e) => {
        setFetchState("error");
        console.error(e);
      });
  }, []);

  if (openArticle) {
    return (<Article url={openArticle.url} closeArticle={() => setOpenArticle(undefined)} />);
  }

  return (
    <div className={styles.body}>
      {
        (() => {
          switch (fetchState) {
            case "none":
            case "fetching":
              return <CircularProgress />;
            case "error":
              return "ERROR";
            case "done":
              return (
                articles.slice(paginationLimit * (page - 1), paginationLimit * page).map((article) =>
                  <div key={article.url} className={styles.articleDiv}>
                    <Link
                      className={readUrls.includes(article.url) ? styles.readArticleLink : ""}
                      component="button"
                      variant="body1"
                      onClick={() => {
                        setOpenArticle(article);
                        setReadUrl(article.url);
                        play();
                        pause();
                      }}
                    >
                      {article.title}
                    </Link>
                  </div>
                )
              )
          }
        })()
      }
      <Pagination
        page={page}
        onChange={(e, p) => {
          setPage(p);
          window.history.replaceState(null, null as any, `?page=${p}`);
        }}
        count={Math.ceil(articles.length / paginationLimit)}
        shape="rounded"
        size="large"
        disabled={fetchState !== "done"}
      />
    </div>
  );
};

export default Home;
