const apiKey = "AIzaSyBCkpqgv0Ms6eBXoPO26Lo8hueopu6xbFA";

const textToSpeech = async (text: string) => {
  const response = await fetch(
    "https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=" + apiKey,
    {
      method: "POST",
      body: JSON.stringify({
        input: { text },
        voice: { languageCode: "cmn-CN", name: "cmn-CN-Wavenet-A" },
        audioConfig: { audioEncoding: "MP3" }
      })
    }
  );

  return (await response.json()).audioContent;
};

export { textToSpeech };
