const audio = new Audio();

const setAudioSource = (str: string) => {
  audio.src = "data:audio/mp3;base64," + str;
};

const play = () => {
  audio.play();
}

const pause = () => {
  audio.pause();
};

export { setAudioSource, play, pause };
