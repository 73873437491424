import React, { FunctionComponent, useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";

import { WxcArticle, fetchArticle } from "./WxcArticleParser";
import { textToSpeech } from "../TextToSpeech";
import { setAudioSource, play, pause } from "../AudioPlayer";

import styles from "./Article.module.css";

interface Props {
  url: string;
  closeArticle: () => void;
}

const Article: FunctionComponent<Props> = (props) => {
  const { url, closeArticle } = props;

  const [fetchState, setFetchState] = useState<"none" | "fetching" | "error" | "done">("none");
  const [article, setArticle] = useState<WxcArticle>();
  const [audioReady, setAudioReady] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(true);

  useEffect(() => {
    return () => {
      if (playing) {
        pause();
      }
    };
  }, [playing]);

  useEffect(() => {
    setFetchState("fetching");
    fetchArticle(url)
      .then((a) => {
        setFetchState("done")
        setArticle(a);
        textToSpeech(a.body)
          .then((audio) => {
            setAudioReady(true);
            setAudioSource(audio);
            play();
          });
      })
      .catch((e) => {
        setFetchState("error");
        console.error(e);
      });
  }, [url]);

  return (
    <div className={styles.body}>
      <div className={styles.controlPanel}>
        <Button
          className={styles.actionButton}
          variant="outlined"
          color="secondary"
          onClick={closeArticle}
          disableElevation
        >
          <ArrowBackIcon className={styles.buttonIcon} />
        </Button>
        <Button
          className={styles.actionButton}
          variant="outlined"
          color="primary"
          onClick={() => {
            if (playing) {
              pause();
              setPlaying(false);
            } else {
              play();
              setPlaying(true);
            }
          }}
          disabled={!audioReady}
          disableElevation
        >
          {
            audioReady
              ? playing
                ? <PauseIcon className={styles.buttonIcon} />
                : <PlayArrowIcon className={styles.buttonIcon} />
              : <CircularProgress />
          }
        </Button>
      </div>
      <div className={styles.iframeContainer}>
        <iframe className={styles.iframe} src={url} title="." frameBorder="0"></iframe>
      </div>
    </div>
  );
};

export default Article;
