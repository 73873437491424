import React from "react";

import Home from "./Home";
import "./App.css";

function App() {
  return (
    <Home />
  );
}

export default App;
