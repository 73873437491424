import { corsFetch } from "../CorsFetch"; 

interface WxcArticle {
  body: string;
}

const fetchArticle = async (url: string): Promise<WxcArticle> => {
  const article = document.createElement("html");
  article.innerHTML = await corsFetch(url);

  let body = "";
  article.querySelectorAll("article#articleContent p").forEach((p) => {
    p.childNodes.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        body += node.textContent + "\n";
      }
    });
  });
  return { body };
};

export type { WxcArticle };
export { fetchArticle };
