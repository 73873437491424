import { corsFetch } from "../CorsFetch"; 

interface WxcHomepageArticle {
  title: string;
  url: string;
}

const fetchHomepage = async () => {
  const homepage = document.createElement("html");
  homepage.innerHTML = await corsFetch("https://www.wenxuecity.com/");
  const articles: WxcHomepageArticle[] = [];

  homepage.querySelectorAll(".col li a").forEach((element) => {
    const href = element.getAttribute("href")!;

    if (!element.classList.contains("adslink") && href.startsWith("/news")) {
      articles.push({ title: element.innerHTML.trim(), url: "https://www.wenxuecity.com/" + href });
    }
  });

  return articles;
}

export type { WxcHomepageArticle };
export { fetchHomepage };
